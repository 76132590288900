export const PROJECT_NAME = 'Foodyman marketplace';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://api.fortech-services.com';
export const WEBSITE_URL = 'https://foodyman.org';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyBbfnvObzwcs6OLXOmSaG9CtIhwjmBwLTQ';
export const export_url = BASE_URL + '/storage/';
export const example = BASE_URL + '/';

export const VAPID_KEY =
  'BANrzOf0cHETxGIXKTF-En8Bp9lXvFeAuQ--QqyR5Qekx3X9wsAGSN-VnH-kteA4cQbWO6ygYgD4iCz3i0hl5Gk';

export const LAT = 47.4143302506288;
export const LNG = 8.532059477976883;

export const API_KEY = 'AIzaSyAOmJ_M-QmS48sA089JUba9J3d-drlvrtU';
export const AUTH_DOMAIN = 'foodie-e30f7.firebaseapp.com';
export const PROJECT_ID = 'foodie-e30f7';
export const STORAGE_BUCKET = 'foodie-e30f7.appspot.com';
export const MESSAGING_SENDER_ID = '449218054836';
export const APP_ID = '1:449218054836:web:27acae5628789116d665e1';
export const MEASUREMENT_ID = 'G-Q04E2YJVQX';

export const RECAPTCHASITEKEY = '6LesdI0mAAAAAOsL4S5ZsqGmaW5VTw6z8bc7_maZ';

export const DEMO_SELLER = 334; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
